<template>
    <van-cell-group class="shadow-md" inset>
        <van-cell :title="className" title-class="font-bold">
            <template #value>
                <span>{{ datesText }}</span>
            </template>
        </van-cell>
        <van-cell :title="lang[langCode].teachers">
            <template #value>
                <div class="divide-x flex justify-end">
                    <span class="pl-2" :class="{ 'pr-2': index !== classDetails.teachers.length - 1 }"
                        v-for="(teacher, index) in classDetails.teachers" :key="index">{{ teacher.name }}<span
                            v-if="isManager && teacher.type">({{ TeacherTypes[teacher.type][langCode] }})</span></span>
                </div>
            </template>
        </van-cell>
        <van-cell :title="lang[langCode].plan">
            <template #value>
                <session-time v-for="(slot, index) in classDetails.classTimes" :key="index" :day="slot.day"
                    :time-start="slot.timeStart" :time-end="slot.timeEnd" :lang-code="langCode"
                    :duration="slot.duration" />
            </template>
        </van-cell>
        <van-cell :title="lang[langCode].key">
            <template #value>
                <van-tag class="ml-3 mb-1" color="#6665a3" size="medium"
                    v-for="(info, index) in classDetails.classMetaInfo" :key="index">{{ info }}</van-tag>
                <van-tag class="ml-3 mb-1" size="medium" type="success" v-if="isStudent && classDetails.studentAdmit">{{
                    lang[langCode].admit }}</van-tag>
                <van-tag class="ml-3 mb-1" size="medium" type="warning"
                    v-if="isStudent && !classDetails.studentAdmit">{{ lang[langCode].notAdmit }}</van-tag>
            </template>
        </van-cell>
        <van-cell title="允许报名" v-if="isManager || isTeacher">
            <template #value>
                <van-switch v-model="join" size="20px" active-color="#07c160" @change="setJoin" />
            </template>
        </van-cell>
        <van-cell title="允许搜索" v-if="isManager || isTeacher">
            <template #value>
                <van-switch v-model="search" size="20px" active-color="#07c160" @change="setSearch" />
            </template>
        </van-cell>
        <van-cell title="自动录取" v-if="isManager || isTeacher">
            <template #value>
                <van-switch v-model="admit" size="20px" active-color="#07c160" @change="setAdmit" />
            </template>
        </van-cell>
        <van-cell title="Credit Cost" v-if="isManager" :value="classDetails.classDates.cost" />
        <van-cell title="" v-if="(isManager || isTeacher) && classDetails.cover" is-link>
            <template #value>
                <a :href="Env.classPath + classDetails.cover" target="_blank">Cover</a>
            </template>
        </van-cell>
        <van-cell>
            <template #value>
                <div class="flex flex-row-reverse flex-wrap">
                    <van-button v-if="isManager" class="ml-4 mb-2" plain type="primary" size="small"
                        @click="pickTeacher()">设定老师</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="warning" size="small"
                        @click="goManageStudents()">{{ lang[langCode].buttonStudents }}</van-button>
                    <van-button class="ml-4 mb-2" plain type="warning" size="small" @click="goTasks()">课程任务</van-button>
                    <van-button v-if="isTeacher" class="ml-4 mb-2" plain type="primary" size="small"
                        @click="goManageSessions()">{{ lang[langCode].buttonLesson }}</van-button>
                    <van-button v-if="canManageStudent" class="ml-4 mb-2" plain type="success" size="small"
                        @click="goAttendance()">考勤统计</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="warning" size="small"
                        @click="editClass()">修改课程</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="warning" size="small"
                        @click="goClassPay()">支付方案</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="warning" size="small"
                        @click="goUploadImage()">上传封面</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="primary" size="small"
                        @click="goForm()">报名需资</van-button>
                    <van-button v-if="isManager || isTeacher" class="ml-4 mb-2" plain type="primary" size="small"
                        @click="copyClass()">复制课程</van-button>
                    <van-button v-if="isManager" class="ml-4 mb-2" plain type="danger" size="small"
                        @click="deleteClass()">删除课程</van-button>
                    <van-button v-if="isStudent" class="ml-4 mb-2" plain type="danger" size="small"
                        @click="quitClass()">{{ lang[langCode].buttonQuit }}</van-button>
                    <van-button v-if="isStudent && classDetails.payPlans.length" class="ml-4 mb-2" plain type="primary"
                        size="small" @click="payClass()">{{ lang[langCode].buttonPay }}</van-button>
                    <van-button v-if="isStudent" class="ml-4 mb-2" plain type="primary" size="small"
                        @click="checkTrans()">{{ lang[langCode].buttonRecord }}</van-button>
                </div>
            </template>
        </van-cell>
    </van-cell-group>
</template>

<script>
import lang from "@/langs/ClassListCardLang.js";
import {
    ref,
    computed
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
    Cell,
    CellGroup,
    Button,
    Tag,
    Switch
} from "vant";
import SessionTime from "@/components/text/SessionTime.vue";
import Helpers from "@/logics/Helpers.js";
import GetMonths from "@/logics/GetMonthsInRange.js";
import TeacherTypes from "@/static_values/StaticTeacherTypes.js";
import Env from "@/logics/Envs.js";

export default {
    name: "ClassListCard",
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Tag.name]: Tag,
        [Switch.name]: Switch,
        SessionTime
    },
    props: {
        classDetails: Object,
        classIndex: Number,
        isTeacher: Boolean,
        isManager: Boolean,
        isStudent: Boolean,
        langCode: {
            type: String,
            default: "zh"
        },
        canManageStudent: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {
        emit
    }) {
        const store = useStore();
        const router = useRouter();
        const today = new Date();

        const className = computed(() => {
            if (!props.classDetails.langs) {
                return props.classDetails.className;
            }
            if (!props.classDetails.langs[props.langCode]) {
                return props.classDetails.className;
            }
            return props.classDetails.langs[props.langCode].className;
        });

        const join = ref(props.classDetails.join);
        const setJoin = function (event) {
            emit("changeJoin", event);
        };

        const search = ref(props.classDetails.search);
        const setSearch = function (event) {
            emit("changeSearch", event);
        };

        const admit = ref(props.classDetails.admit);
        const setAdmit = function (event) {
            emit("changeAdmit", event);
        };

        const pickTeacher = function () {
            emit("pickTeacher", props.classIndex);
        };
        const editClass = function () {
            store.commit("setClassDetails", JSON.parse(JSON.stringify(props.classDetails)));
            router.push("/createClass/edit");
        };
        const copyClass = function () {
            store.commit("setClassDetails", JSON.parse(JSON.stringify(props.classDetails)));
            router.push("/createClass/copy");
        };
        const goManageStudents = function () {
            store.commit("setClassDetails", props.classDetails);
            router.push("/classStudents/" + props.classDetails.classId);
        };
        const goTasks = function () {
            store.commit("setClassDetails", props.classDetails);
            router.push("/classTask/" + props.classDetails.classId);
        };
        const goManageSessions = function () {
            store.commit("setClassDetails", props.classDetails);
            const actions = [];
            GetMonths([props.classDetails.classDates.start.year, props.classDetails.classDates.start.month], [today.getFullYear(), today.getMonth() + 1], function (y, m) {
                let monthName = y + "年" + m + "月";
                if (props.langCode === "en") {
                    monthName = y + "/" + m
                }
                actions.unshift({
                    name: monthName,
                    value: "/classSession/" + props.classDetails.classId + "/" + y + "/" + m
                });
            });
            emit("goSessions", actions);
        };
        const goAttendance = function () {
            const actions = [];
            GetMonths([props.classDetails.classDates.start.year, props.classDetails.classDates.start.month], [today.getFullYear(), today.getMonth() + 1], function (y, m) {
                actions.unshift({
                    name: y + "年" + m + "月",
                    value: "/classAttendance/" + props.classDetails.classId + "/" + y + "/" + m + "/20"
                });
            });
            emit("goAttendance", actions);
        };
        const goClassPay = function () {
            store.commit("setClassDetails", props.classDetails);
            router.push("/classPayments/" + props.classDetails.classId);
        };
        const deleteClass = function () {
            emit("deleteClass");
        };
        const quitClass = function () {
            emit("quitClass");
        };
        const payClass = function () {
            emit("payClass");
        };
        const checkTrans = function () {
            emit("checkTrans");
        };
        const goUploadImage = function () {
            emit("uploadImage");
        };
        const goForm = function () {
            store.commit("setClassDetails", props.classDetails);
            router.push("/classForm/" + props.classDetails.classId);
        };

        return {
            Env,
            lang,
            join,
            setJoin,
            search,
            setSearch,
            admit,
            setAdmit,
            datesText: computed(() => Helpers.makeDateRange(props.classDetails.classDates.start, props.classDetails.classDates.end, props.langCode)),
            pickTeacher,
            editClass,
            copyClass,
            goManageStudents,
            goManageSessions,
            goAttendance,
            goClassPay,
            deleteClass,
            quitClass,
            payClass,
            checkTrans,
            goUploadImage,
            TeacherTypes,
            goForm,
            goTasks,
            className
        };
    }
}
</script>
